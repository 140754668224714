import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import scrollTo from 'gatsby-plugin-smoothscroll';
export default function Sectwo(){
    return (
        <>
        
             <Container fluid className="__bg-secondary-sl">
                <Container>
                    <Row className="fade-in">
                        <Col lg={12}>
                            <div className="__image_ipad-sl">
                                <img 
                                    src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/attraction-mastery/Devices.png"
                                    alt="logo" 
                                    className="img img-fluid lazyload"/>
                            </div>
                            <div className="d-flex justify-content-center wl-am-fr mt-3">
                                
                                    <Button variant="primary" size="lg" onClick={() => scrollTo('#buy-up')}>
                                        YA SAYA MAU DAFTAR
                                    </Button>{' '}
                               
                               
                            </div>
                                <p className="text-center pt-3 __text-link" onClick={() => scrollTo('#buy-up')}><u>( klik disini untuk instan akses )</u></p>
                        </Col>
                    </Row>
                    <Row className="fade-in __margin-top-sl-100">
                        <Col lg={6} className="order-lg-last order-md-last">
                            <div className="__wrapper-img-headline-sl">
                                <img
                                    src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/attraction-mastery/girl-.png"
                                    alt="image am"
                                    className="img img-fluid lazyload"/>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className="__wrappe-headline-sl">
                                <h2>Sadarkah Kamu</h2>
                                <p>
                                    banyak wanita mengalami kesulitan menaikkan level hubungan dari fase kenalan ke fase berikutnya hingga berujung komitmen pernikahan karena TIDAK TAU bagaimana caranya menciptakan dan meningkatkan ketertarikan.
                                </p>
                                <p>
                                    Tidak masalah bagi mereka membuat banyak pria ingin berkenalan,  namun jika ditanya berapa banyak yang lanjut? Hmmm.. disanalah sumber masalahnya.
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12} xs={12}>
                            <div className="__bg-white-rounded-sl __margin-top-sl-50">
                                <Row className="fade-in">
                                    <Col lg={12}>    
                                        <div className="__wrappe-headline-sl">
                                            <h2>Apakah Kamu sedang mengalami hal ini?</h2>
                                        </div>
                                    </Col>
                               
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <div className="d-flex">
                                            <div className="__width-icon-and-text">
                                                <FontAwesomeIcon icon={faCheckCircle} className="__fa-check-circle-sl" style={{color:"green"}}/>                               
                                            </div>
                                            <p className="__text-desc-icon-sl">
                                            Ingin   bisa membuat pria yang kamu suka, juga menyukaimu
                                            </p>
                                        </div>
                                    </Col>

                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <div className="d-flex">
                                            <div className="__width-icon-and-text">
                                                <FontAwesomeIcon icon={faCheckCircle} className="__fa-check-circle-sl" style={{color:"green"}}/>                               
                                            </div>
                                            <p className="__text-desc-icon-sl">
                                                Ingin bisa bergaul dengan asyik, seru dan dekat di kalangan pria populer 
                                            </p>
                                        </div>
                                    </Col>

                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <div className="d-flex">
                                            <div className="__width-icon-and-text">
                                                <FontAwesomeIcon icon={faCheckCircle} className="__fa-check-circle-sl" style={{color:"green"}}/>                               
                                            </div>
                                            <p className="__text-desc-icon-sl">
                                                Menjadi wanita yang mudah disukai tanpa perlu menjadi seseorang yang bukan kamu 
                                            </p>
                                        </div>
                                    </Col>

                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <div className="d-flex">
                                            <div className="__width-icon-and-text">
                                                <FontAwesomeIcon icon={faCheckCircle} className="__fa-check-circle-sl" style={{color:"green"}}/>                               
                                            </div>
                                            <p className="__text-desc-icon-sl">
                                                Ingin menghangatkan hubungan yang sudah dingin tapi bingung mulai dari mana
                                            </p>
                                        </div>
                                    </Col>

                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <div className="d-flex">
                                            <div className="__width-icon-and-text">
                                                <FontAwesomeIcon icon={faCheckCircle} className="__fa-check-circle-sl" style={{color:"green"}}/>                               
                                            </div>
                                            <p className="__text-desc-icon-sl">
                                                Gak paham cara membuat seseorang bisa tertarik sama kamu 
                                            </p>
                                        </div>
                                    </Col>

                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <div className="d-flex">
                                            <div className="__width-icon-and-text">
                                                <FontAwesomeIcon icon={faCheckCircle} className="__fa-check-circle-sl" style={{color:"green"}}/>                               
                                            </div>
                                            <p className="__text-desc-icon-sl">
                                            Kurang percaya bahwa dirimu cukup menarik!
                                            </p>
                                        </div>
                                    </Col>
                                    
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <div className="d-flex">
                                            <div className="__width-icon-and-text">
                                                <FontAwesomeIcon icon={faCheckCircle} className="__fa-check-circle-sl" style={{color:"green"}}/>                               
                                            </div>
                                            <p className="__text-desc-icon-sl">
                                            Sering mati kutu ketika berada di depan pria yang kamu suka! 
                                            </p>
                                        </div>
                                    </Col>
                                    
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <div className="d-flex">
                                            <div className="__width-icon-and-text">
                                                <FontAwesomeIcon icon={faCheckCircle} className="__fa-check-circle-sl" style={{color:"green"}}/>                               
                                            </div>
                                            <p className="__text-desc-icon-sl">
                                            Terlalu banyak “self-talk” yang akhirnya membuatmu gak mulai-mulai karena khawatir bikin il-feel 
                                            </p>
                                        </div>
                                    </Col>

                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <div className="d-flex">
                                            <div className="__width-icon-and-text">
                                                <FontAwesomeIcon icon={faCheckCircle} className="__fa-check-circle-sl" style={{color:"green"}}/>                               
                                            </div>
                                            <p className="__text-desc-icon-sl">
                                            Ingin membuat hubungan yang sudah dekat jadi lebih serius tanpa perlu nembak dan nagih kepastian 
                                            </p>
                                        </div>
                                    </Col>
                                   
                                </Row> 
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>
           

        </>
    )
}

